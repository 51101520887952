<template>
  <div
    :class="`d-flex align-center logo-block pa-1 ` + (this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light')"
    @click="goHome"
  >
    <v-avatar>
      <v-img
        :src="require('../../assets/logo.png')"
      />
    </v-avatar>
    <div  class="logo-title">
      Wendy
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoBlock',
  props: ['subtitle'],
  methods: {
    goHome() {
      if (this.$route.path !== '/home') {
        this.$router.push('/home');
      }

    }
  }
};
</script>

<style scoped>
.logo-title {
  font-family: Roboto, sans-serif;
  font-size: 2em;
  line-height: 1;
  font-weight: 400;
  margin-left: 4px;
  /*letter-spacing: -0.14em;*/
}

.logo-block {
  width: 156px; /*width: 138px;*/
  cursor: pointer;
  border-radius: 5px;
}
.theme--light.logo-block:hover {
  background-color: #d8e8f7;
}

.theme--dark.logo-block:hover {
  background-color: #22252a;
}
</style>
