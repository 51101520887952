import Vue from 'vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import createVuetify from './plugins/vuetify';
import AppPlugin from './utils/AppPlugin';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import '@/utils/WindowSize';

// patch

if (!window.crypto.randomUUID) {
  window.crypto.randomUUID = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

// init data for app

const plugins = [
  new AppPlugin('store', ['app', 'main', 'persist']),
  new AppPlugin('api', ['auth', 'app', 'docs', 'main', 'candidates', 'DocumentGenerator', 'hh', 'hr'], true),
];

Vue.config.productionTip = false;

// for IDE highlighting
Vue.prototype.$api = null;
Vue.prototype.$store = null;

// Vue.prototype.$keycloak = Keycloak(initOptions);

(async function () {

  await Promise.all(plugins.map(async plugin => {
    Vue.use(plugin);
    await plugin.wait();
  }));

  // init routing logic

  router.beforeEach((to, from, next) => {
    if (to.meta?.isExcludedAuth) {
      next();
      return;
    }
    const isAuth = !!router.app.$store.persist.d.authToken;
    if (to.path !== '/login' && !isAuth) {
      next({path: '/login'});
    } else if (to.path === '/login' && isAuth) {
      next({path: '/'});
    } else {
      next();
    }
  });

  router.beforeEach(async (to, from, next) => {
    if (to.query.code) {
      try {
        const previousRoute = localStorage.getItem('previousRoute');
        await router.app.$api.hh.fetchToken(to.query.code, previousRoute);
        if (previousRoute) {
          next(previousRoute);
          localStorage.removeItem('previousRoute');
        } else {
          next();
        }
      } catch (e) {
        console.error(e);
        router.app.$api.app.snackError('Не удалось авторизоваться в HeadHunter');
      }
    } else {
      next();
    }
  });

  // render app

  const app = new Vue({
    router,
    vuetify: createVuetify({isDark: Vue.prototype.$store.persist.d.isThemeDark}),
    watch: {
      '$store.persist.d.isThemeDark': {
        handler(v) {
          app.$vuetify.theme.isDark = v;
        },
      },
      '$route.path'() {
        // TODO: fetch and process data by page opening
      },
    },
    render: h => h(App),
  });

  // init data for debug

  // eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    window.__app = app;
  }

  // init app data

  plugins.forEach(plugin => {
    plugin.setVueInstance(app);
  });

  // app.$store.app.toolbarLoading = true;
  app.$store.main.s.app = 'admin';

  // init request manager

  // Vue.prototype.$rm = new RequestManager(app);

  // mount app

  app.$mount('#app');

  // init app logic

  try {

    // check app version
    if (app.$store.persist.d.version?.toString() !== app.$store.main.d.version?.toString()) {
      const v = parseFloat(app.$store.persist.d.version?.toString());
      if (v < 1 || isNaN(v)) {
        app.$api.main.cleanLocalData();
        app.$store.persist.d.version = app.$store.main.d.version.toString();
        window.location.reload();
        return;
      }
      app.$store.persist.d.version = app.$store.main.d.version.toString();
    }

    // check auth
    const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/check`, {
      headers: {
        Authorization: app.$store.persist.d.authToken,
      },
    });
    if (response.status === 401) {
      app.$api.auth.signOut();
      app.$router.push('/login').catch(() => {
      });
    } else {
      app.$store.persist.d.companyId = (await (await fetch(`${process.env.VUE_APP_API_URL}/models/company/my`, {
        headers: {Authorization: app.$store.persist.d.authToken},
      })).json())?.data?.id;
    }

    app.$api.auth.init();

    /**
     *  если бэкенд был запущен последний раз позже, чем мы кэшировали данные,
     *  то обновляем мета данные, иначе обрабатываем сырые мета данные из кэша.
     *
     *  TODO: fetch and process data
     */

    // load or mock projects jobs
    // if (app.$store.persist.s.projectsJobs) {
    //   try {
    //     app.$store.main.s.projects = JSON.parse(app.$store.persist.s.projectsJobs).sort((a, b) => b.createdAt - a.createdAt)
    //   } catch (e) {
    //     console.error(e);
    //   }
    // } else {
    //   app.$store.persist.s.projectsJobs = JSON.stringify([]);
    //   app.$store.main.s.projects = [];
    // }

    // app is ready
    app.$store.main.d.isAppReady = true;
    app.$store.main.isAppReady.value = true;

  } catch (e) {
    console.error('Errored starting app');
    console.error(e);
  }
})();
