<template>
    <div
            class="card-button align-center my-2 pa-4"
            :class="cardClass"
    >
        <div class="d-flex flex-column">
          <div class="d-flex align-center mb-2">
            <div v-if="createdAt" class="caption">{{new Date(createdAt).toLocaleString('ru')}}</div>
            <v-spacer/>
            <div v-if="code" class="body-2 ml-2"><b>Code</b> {{ code }}</div>
            <div v-if="extId" class="body-2 ml-2"><b>Ext ID</b> {{ extId }}</div>
            <div v-if="status === 'draft'" class="caption ml-2">Черновик</div>
          </div>
            <div class="d-flex align-center mb-1 mb-sm-3" style="min-height: 32px">
                <h4 v-if="name" class="flex-grow-1">{{ name }}</h4>
                <div v-else class="font-italic" style="font-size: 14px">Название проекта не указано</div>
            </div>
            <div
                    v-if="description"
                    class="mb-1 mb-sm-3"
            >
                {{ description }}
            </div>
        </div>
        <v-spacer/>
        <div class="d-flex align-center">
          <v-btn depressed :to="`/projects/${id}`">Открыть</v-btn>
          <v-spacer/>
          <div v-if="companyName">Компания: {{ companyName }}</div>
        </div>
    </div>
</template>

<script>
export default {
  name: "CardProject",
  props: ['name', 'description', 'createdAt', 'updatedAt', 'status', 'id', 'extId', 'code', 'companyName'],
  computed: {
    cardClass() {
      return (this.$vuetify.breakpoint.smAndUp ? '' : 'card-button-mobile') + ' ' +
        (this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light');
    },
  },
};
</script>

<style scoped>

.card-button {
    border-radius: 5px;
    //width: 100%;
}

/*.card-button-mobile {*/
/*    width: 100%;*/
/*    padding: 12px 8px 8px 12px !important;*/
/*    min-height: inherit;*/
/*}*/

.theme--light.card-button {
    border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.card-button {
    background-color: #1E1E1E;
    color: #FFFFFF;
    border: thin solid rgba(255, 255, 255, 0.12);
}
</style>
